import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { consultivoFiltroSelector } from "./consultivoFiltro";

const initialState = {
  filtro: {
    empresaId: null,
    unidadeId: null,
    status: null,
    dataAberturaDe: null,
    dataAberturaAte: null,
    idExterno: null,
    prioridade: null,
    tempoEstimadoDe: null,
    tempoEstimadoAte: null,
    dataInicioDe: null,
    dataInicioAte: null,
    dataConclusaoDe: null,
    dataConclusaoAte: null,
    titulo: null,
    sigilo: null,
    descricao: null,
    usuarioSolicitanteId: null,
    usuarioCriadorId: null,
    usuarioControleId: null,
    usuarioResponsavelId: null,
    emailsExtras: [],
    createdAtDe: null,
    createdAtAte: null,
    updatedAtDe: null,
    updatedAtAte: null,
    id: null,
    resposta: null,
    tags: [],
    permissoes: null,
    projetoId: null,
    consultaOrigemId: null,
  },

  consultas: [],
};

export const filtroSelector = state => state.consultivoPesquisa.filtro;
export const consultasSelector = state => state.consultivoPesquisa.consultas;

export const fetchConsultas = createAsyncThunk(
  'consultivoPesquisa/fetchConsultas',
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const filtro = { ...consultivoFiltroSelector(state), ...filtroSelector(state) };
      const { data } = await axios.get('/consultivo/pesquisa/consultas', { params: filtro });
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const slice = createSlice({
  name: 'consultivoPesquisa',
  initialState,
  reducers: {
    resetar: (state) => {
      return initialState;
    },
    filtrar: (state, action) => {
      state.filtro = { ...state.filtro, ...action.payload };
    }
  },
  extraReducers: {
    [fetchConsultas.fulfilled]: (state, action) => {
      state.consultas = action.payload;
    }
  },
});

export default slice.reducer;

export const { resetar, filtrar } = slice.actions;
