import AuthenticatedLayout from "../../../layouts/authenticated";
import AcessoMenu from "../../../components/acesso-menu";
import ConsultivoMenu from "../../../components/consultivo-menu";
import React, { useEffect } from "react";
import useDispatchWithResult from "../../../hooks/useDispatchWithResult";
import { useSelector } from "react-redux";
import { isGestorSelector } from "../../../store/autenticacao";
import { consultivoFiltroSelector } from "../../../store/consultivoFiltro";
import ConsultasPanel from "./_ConsultasPanel";
import PesquisaPanel from "./_PesquisaPanel";
import styles from './_styles.less';
import { fetchConsultivo } from "../../../store/consultivo";
import { fetchConsultas, filtroSelector, resetar } from "../../../store/consultivoPesquisa";
import { useDebouncedEffect } from "../../../hooks/useDebouncedEffect";

export default function ConsultivoPesquisaPage() {
  const dispatch = useDispatchWithResult();
  const isGestor = useSelector(isGestorSelector);
  const consultivoFiltro = useSelector(consultivoFiltroSelector);
  const filtro = useSelector(filtroSelector);

  useEffect(() => {
    dispatch(fetchConsultivo());
    dispatch(resetar());
  }, []);

  return (
    <AuthenticatedLayout
      modulo="consultivo"
      menu={
        <>
          <AcessoMenu allowClear defaultRoute="/consultivo/pesquisa"/>
          <ConsultivoMenu showCreate={isGestor}/>
        </>
      }
      contentStyle={{ maxWidth: 'unset', padding: 0 }}
    >
      <div className={styles.conteudo}>
        <div className={styles.painelEsquerdo}>
          <PesquisaPanel/>
        </div>
        <div className={styles.painelCentral}>
          <ConsultasPanel/>
        </div>
      </div>
    </AuthenticatedLayout>)
}
